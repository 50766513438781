import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AppMainComponent } from './app.main.component';
import { AuthService } from './_services/auth.service';
import { Utente } from 'src/app/_interfaces/utente';
import { UtentiService } from './_services/utenti.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
    user: string;
    display = false;
    environment = environment;
    ruoloSel: string;
    viewPassword = false;
    ruolo: string;

    frmUser: FormGroup;
    roles: { label: string; value: any }[] = [
        // { label: 'SuperAdmin', value: 'SA' },
        { label: 'Admin', value: 'AD' },
        { label: 'Serramentista', value: 'SE' },
    ];


    constructor(
        public appMain: AppMainComponent,
        private authService: AuthService,
        private utenteSrv: UtentiService,
        private msgService: MessageService) {
        this.ruolo = (sessionStorage.getItem('ruolo') === 'SA' ? 'SuperAdmin' : (sessionStorage.getItem('ruolo') === 'AD' ? 'Admin' : 'Serramentista'));
        this.user = `${sessionStorage.getItem('name').toUpperCase()} (${this.ruolo})`;
    }

    ngOnInit(): void {
        this.creaform();
    }

    creaform(): void {
        this.frmUser = new FormGroup({
            id: new FormControl(0, Validators.required),
            name: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            ruolo: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            abilitato: new FormControl(1, Validators.required),
            interventi: new FormControl("1,2"),
        });
    }

    submit() {
        const updUtente: { id: number; ruolo: string; interventi: string; abilitato: number } = {
            id: this.frmUser.controls.id.value,
            ruolo: this.frmUser.controls.ruolo.value,
            interventi: this.frmUser.controls.interventi.value,
            abilitato: 1,
        };
        this.utenteSrv.updUtente(updUtente).subscribe(() => {
            this.display = false;
            this.creaform();
            this.msgService.add({
                key: 'tst',
                severity: 'success',
                summary: 'Aggiunto Utente',
                detail: 'Utente Registrato Correttamente!'
            });
        });
    }

    editUser(): void {
        this.display = true;
        this.frmUser.controls.id.setValue[sessionStorage.getItem("id")];
        this.frmUser.controls.name.setValue[sessionStorage.getItem("name")];
        this.frmUser.controls.email.setValue[sessionStorage.getItem("email")];
        this.frmUser.controls.ruolo.setValue[sessionStorage.getItem("ruolo")];
        this.frmUser.controls.password.setValue[sessionStorage.getItem("")];
        this.frmUser.controls.abilitato.setValue[sessionStorage.getItem("abilitato")];
        this.frmUser.controls.interventi.setValue["1,2"];
    }

    showDialog(): void {
        this.display = true;
    }

    cancel(): void {
        this.display = false;
        this.creaform();
    }

    doLogout = () => {
        this.authService.logout();
    }

}
