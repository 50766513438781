import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MessageService } from "primeng/api";
import { AutoComplete } from "primeng/autocomplete";
import { Dropdown } from "primeng/dropdown";
import { OverlayPanel } from "primeng/overlaypanel";
import { GeneraForm } from "src/app/_helpers/genera-form";
import { Utility } from "src/app/_helpers/utility";
import { Abitazioni } from "src/app/_interfaces/abitazioni";
import { Cliente, Clienti } from "src/app/_interfaces/clienti";
import { Lavori, LavoriTutti } from "src/app/_interfaces/lavori";
import {
    Catastale,
    ProdottiInstallati,
    TipoAgevolazione,
    TipoIntervento,
    TitolaritaImmobile,
    ZonaClimatica,
} from "src/app/_interfaces/list-type";
import { Citta, Nazioni, Regioni } from "src/app/_interfaces/nazioni";
import { UtcDatePipe } from "src/app/_pipes/utc-date.pipe";
import { AbitazioniService } from "src/app/_services/abitazioni.service";
import { AuthService } from "src/app/_services/auth.service";
import { ClientiService } from "src/app/_services/clienti.service";
import { LavoriService } from "src/app/_services/lavori.service";
import { ListTypeService } from "src/app/_services/list-type.service";
import { NazioniService } from "src/app/_services/nazioni.service";
import { UtentiAttrsService } from "src/app/_services/utenti-attrs.service";
import { UtentiService } from "src/app/_services/utenti.service";
import { environment } from "src/environments/environment";
import { AppBreadcrumbService } from "../../app.breadcrumb.service";
import { Utente } from "./../../_interfaces/utente";
import { UtenteAttrs } from "./../../_interfaces/utente-attrs";

@Component({
    selector: "app-scheda-intervento",
    templateUrl: "./scheda-intervento.component.html",
    styleUrls: ["./scheda-intervento.component.scss"],
})
export class SchedaInterventoComponent implements OnInit {
    id: string;
    cliente: Cliente;
    clienti: Clienti[];
    tabChange: number = 0; //mod

    OffeteApprovateCliente: number = 0;
    OffeteApprovateAbitazione: number = 0;

    nazioni: Nazioni[] = [];
    regioni: Regioni[] = [];
    citta: Citta[] = [];
    nazioniSuggestion: any[] = [];
    regioniSuggestion: any[] = [];
    cittaSuggestion: any[] = [];
    utenti: Utente[];
    abitazioni: Abitazioni[];
    titImmobile: TitolaritaImmobile[];
    tipAgevolazioni: TipoAgevolazione[];
    tipoIntervento: TipoIntervento[];
    tipoInterventoBC: TipoIntervento[];

    zonelimatiche: ZonaClimatica[];
    filtroZonelimatiche: ZonaClimatica[];

    lavori: LavoriTutti[];
    categoriaCatastale: Catastale[];

    serramentoTipoProdotto: ProdottiInstallati[];
    filtroTipoProdotto: ProdottiInstallati[];

    enabledSerr: boolean = true;

    TipoIntervetoOfferta: string = "intervento";

    lavoro: Lavori;
    abitazioneScelta: Abitazioni;

    legaleVisible = false;
    titoloScheda: string;
    environment = environment;

    UtenteName: string;
    UtenteID: string;
    ruolo: string;

    modalAbitazione = false;
    modalIntervento = false;
    modalInterventoBonusCasa = false;
    modalZonaClimatica = false;
    drpTipoCliente = true;
    bloccaTipoUnita: boolean = true;

    ArrEsposizioneSchermatureSolari = [];
    ArrEsposizioneChiusureOscuranti = [];
    ArrTipologiaBC = [];

    selZonaClimatica: string = "";
    selGTOT: string = "";

    buttonDisabled: boolean = false;
    nuovapraticaSI: boolean = false;

    Tipologia: { label: string; value: any }[] = [
        { label: "Persona Fisica", value: "fisica" },
        { label: "Persona Giuridica", value: "giuridica" },
    ];

    TipologiaImmobiliare: { label: string; value: any }[] = [
        { label: "Singola unità abitativa", value: "Singola unità abitativa" },
        {
            label: "Parti comuni condominiali",
            value: "Parti comuni condominiali",
        },
    ];

    TipoDetrazione: { label: string; value: any }[] = [
        { label: "ECO Bonus", value: "ECO Bonus" },
        { label: "Bonus Casa", value: "Bonus Casa" },
    ];

    TipoDocumento: { label: string; value: any }[] = [
        { label: "Carta Identità", value: 1 },
        { label: "Passaporto", value: 2 },
        { label: "Patente", value: 3 },
    ];

    chiusuraOscurante: { label: string; value: any }[] = [
        { label: "N/D", value: 99 },
        { label: "SI", value: 1 },
        { label: "NO", value: 0 },
        { label: "<=0.35", value: 2 },
    ];

    haPartitaIva: { label: string; value: any }[] = [
        { label: "SI", value: "SI" },
        { label: "NO", value: "NO" },
    ];

    filtroChiusuraOscurante: { label: string; value: any }[] =
        this.chiusuraOscurante;
    attrSI: UtenteAttrs[] = [];

    frmCliente: FormGroup;
    frmAbitazione: FormGroup;
    frmLavoro: FormGroup;
    frmBonusCasa: FormGroup;

    @ViewChild("cl_regione_nascita", { static: false })
    cl_regione_nascita: AutoComplete;
    @ViewChild("cl_citta_nascita", { static: false })
    cl_citta_nascita: AutoComplete;
    @ViewChild("r_regione", { static: false }) r_regione: AutoComplete;
    @ViewChild("r_citta", { static: false }) r_citta: AutoComplete;
    @ViewChild("drpTipologiaIntervento", { static: false })
    drpTipologia: Dropdown;

    @ViewChild("opESS", { static: false }) opESS: OverlayPanel;
    @ViewChild("opZC", { static: false }) opZC: OverlayPanel;
    @ViewChild("opECO", { static: false }) opECO: OverlayPanel;
    @ViewChild("opGTOT", { static: false }) opGTOT: OverlayPanel;
    @ViewChild("opBC", { static: false }) opBC: OverlayPanel;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private route: ActivatedRoute,
        private clientiSrv: ClientiService,
        private nazioniSrv: NazioniService,
        private msgService: MessageService,
        private authSrv: AuthService,
        private abitazioneSrv: AbitazioniService,
        private confirmService: ConfirmationService,
        private utcDatePipe: UtcDatePipe,
        private router: Router,
        private listTypeSrv: ListTypeService,
        private InterventiSrv: LavoriService,
        private utenteSrv: UtentiService,
        private utenteAttrsSrv: UtentiAttrsService
    ) { }

    async ngOnInit(): Promise<void> {
        this.tabChange = 0;
        this.OffeteApprovateCliente = 0;
        this.OffeteApprovateAbitazione = 0;
        this.modalAbitazione = false;
        this.modalIntervento = false;
        this.modalZonaClimatica = false;
        this.enabledSerr = true;
        this.id = this.route.snapshot.paramMap.get("id") ?? "0";
        this.ruolo = sessionStorage.getItem("ruolo");
        this.createFormCliente();
        this.creaFormAbitazione();
        this.CreaFrmLavoro();
        this.CreaFrmBonusCasa();
        this.refreshClienti();
        this.refreshNazioni();
        this.refreshRegioni();
        this.refreshTitolariImmobili();
        this.refreshTipologieAgevolazioni();
        this.refreshInterventiCliente(this.id);
        this.refreshTipoloInterventi();
        this.refreshCategoriaCAtastale();
        this.refreshZoneClimatiche();
        this.refreshProdottiInstallati();
        this.refreshUtenti();
        this.breadcrumbService.setItems([
            {
                label:
                    this.id === "0"
                        ? "Nuova / Scheda Intervento"
                        : `Schede Intervento/${this.id}`,
            },
        ]);
        if (this.id !== "0") {
            this.refresh(this.route.snapshot.paramMap.get("id"));
            this.titoloScheda = ``;
            this.drpTipoCliente = false;
            if (!this.clientiSrv.editUserValue) {
                this.tabChange = 1;
            }
        } else {
            this.titoloScheda = "- Nuova";
            this.frmCliente.get("cl_regione_nascita").clearValidators();
            this.frmCliente.get("cl_provincia_nascita").clearValidators();
            this.frmCliente
                .get("cl_regione_nascita")
                .setValidators([
                    GeneraForm.requiredIfFisica,
                    GeneraForm.requiredIfItalian,
                ]);
            this.frmCliente
                .get("cl_provincia_nascita")
                .setValidators([
                    GeneraForm.requiredIfFisica,
                    GeneraForm.requiredIfItalian,
                ]);
            Utility.updateFormValidity(this.frmCliente);
        }
        this.UtenteName = this.authSrv.getUserName();
        this.UtenteID = this.authSrv.getUserId();
        if (this.ruolo == "SE") {
            this.frmCliente.controls.cl_serramentista.setValue(
                Number(this.authSrv.getUserId())
            );
        }

        // override - se mi viene passato il tab in query selezioni quello
        if (this.route.snapshot.queryParamMap.get("tab")) {
            this.tabChange = Number(
                this.route.snapshot.queryParamMap.get("tab")
            );
        }
        if (this.route.snapshot.queryParamMap.get("abitazione")) {
            const abitazioneId = Number.parseInt(
                this.route.snapshot.queryParamMap.get("abitazione")
            );
            await this.refreshAbitazioni(
                this.route.snapshot.paramMap.get("id")
            );
            const abitazioneScelta = this.abitazioni.find(
                (a) => a.ab_id == abitazioneId
            );
            this.EditAbitazione(abitazioneScelta);
        }
    }

    createFormCliente() {
        this.frmCliente = GeneraForm.createFormCliente();

        this.frmCliente.get("cl_tipo").valueChanges.subscribe((value) => {
            if (value === "giuridica") {
                this.frmCliente.get("cl_regione_nascita").clearValidators();
                this.frmCliente.get("cl_provincia_nascita").clearValidators();
            } else {
                this.frmCliente.get("cl_regione_nascita").clearValidators();
                this.frmCliente.get("cl_provincia_nascita").clearValidators();
                this.frmCliente
                    .get("cl_regione_nascita")
                    .setValidators([
                        GeneraForm.requiredIfFisica,
                        GeneraForm.requiredIfItalian,
                    ]);
                this.frmCliente
                    .get("cl_provincia_nascita")
                    .setValidators([
                        GeneraForm.requiredIfFisica,
                        GeneraForm.requiredIfItalian,
                    ]);
            }
            Utility.updateFormValidity(this.frmCliente);
            Utility.updateFormValidity(
                this.frmCliente.controls["documento"] as FormGroup
            );
        });

        this.frmCliente.get("cl_piva").valueChanges.subscribe((value) => {
            this.frmCliente
                .get("cl_cf")
                .updateValueAndValidity({ emitEvent: false, onlySelf: true });
        });
        this.frmCliente.get("cl_cf").valueChanges.subscribe((value) => {
            this.frmCliente
                .get("cl_piva")
                .updateValueAndValidity({ emitEvent: false, onlySelf: true });
        });

        this.frmCliente
            .get("cl_citta_nascita")
            .valueChanges.subscribe((value) => {
                this.frmCliente.get("cl_citta_nascita").updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                });
            });

        this.frmCliente
            .get("cl_nazione_nascita")
            .valueChanges.subscribe((value) => {
                if (value !== "Italia") {
                    this.frmCliente.get("cl_provincia_nascita").disable();
                    this.frmCliente.get("cl_regione_nascita").disable();
                } else {
                    this.frmCliente.get("cl_provincia_nascita").enable();
                    this.frmCliente.get("cl_regione_nascita").enable();
                }
            });

        (this.frmCliente.get("residenza") as FormGroup)
            .get("r_nazione")
            .valueChanges.subscribe((value) => {
                if (value !== "Italia") {
                    (this.frmCliente.get("residenza") as FormGroup)
                        .get("r_regione")
                        .disable();
                    (this.frmCliente.get("residenza") as FormGroup)
                        .get("r_provincia")
                        .disable();
                } else {
                    (this.frmCliente.get("residenza") as FormGroup)
                        .get("r_regione")
                        .enable();
                    (this.frmCliente.get("residenza") as FormGroup)
                        .get("r_provincia")
                        .enable();
                }
                Utility.updateFormValidity(this.frmCliente);
            });
    }

    creaFormAbitazione() {
        this.frmAbitazione = GeneraForm.createFormAbitazione(this.id);
    }

    CreaFrmLavoro() {
        this.frmLavoro = new FormGroup({
            lav_id: new FormControl(0),
            lav_tipo: new FormControl(this.TipoIntervetoOfferta),
            lav_tipo_unita: new FormControl("", Validators.required),
            lav_tipo_bc: new FormControl(""),
            lav_user: new FormControl(
                this.frmCliente.controls.cl_serramentista.value
            ),
            lav_cliente: new FormControl(null),
            lav_abitazione: new FormControl(0),
            lav_titolarita: new FormControl(0),
            lav_codice: new FormControl(""),
            lav_descrizione: new FormControl("", Validators.required),
            lav_tipologia_intervento: new FormControl("", Validators.required),
            lav_tipologia: new FormControl(""),
            lav_tipo_detrazione: new FormControl(
                "ECO Bonus",
                Validators.required
            ),
            lav_tipologia_agevolazione: new FormControl(
                "",
                Validators.required
            ),
            lav_zona_climatica: new FormControl("", Validators.required),
            lav_prodotto_installato: new FormControl("", Validators.required),
            lav_mq_installati: new FormControl("0", Validators.required),
            lav_mq_installati_co: new FormControl("0", Validators.required),
            lav_transmittanza: new FormControl(0, Validators.required),
            lav_cancellato: new FormControl(0),
            lav_stato: new FormControl(1),
            lav_note: new FormControl(""),
            lav_asseverazione: new FormControl(""),
            lav_visto: new FormControl(""),
            lav_importo: new FormControl(0),
            lav_imp_asseverazione: new FormControl(0),
            lav_iva_offerta: new FormControl(""),
            lav_iva_prestazioni: new FormControl(""),
            lav_iva_attualizzazione: new FormControl(0),
            lav_pag_desc_1: new FormControl(""),
            lav_pag_perc_1: new FormControl(""),
            lav_pag_desc_2: new FormControl(""),
            lav_pag_perc_2: new FormControl(""),
            lav_pag_desc_3: new FormControl(""),
            lav_pag_perc_3: new FormControl(""),
            lav_consegna: new FormControl(""),
            lav_praticaenea: new FormControl(0),
            lav_costoenea: new FormControl(0),
            lav_cessionario: new FormControl(1),
            lav_vendita_infissi: new FormControl(0),
            lav_beni_significativi: new FormControl(0),
            lav_altri_beni: new FormControl(0),
            lav_posa_opera: new FormControl(0),
            lav_altre_opere: new FormControl(0),
            lav_prestazioni_prof: new FormControl(0),
            lav_chiusura: new FormControl("", Validators.required),
            lav_preventivo: new FormControl(""),
            lav_file_step2: new FormControl(""),
            lav_data_aggiunta: new FormControl(""),
            lav_data_modifica: new FormControl(""),
            lav_finanz: new FormControl(0),
            lav_preventiviAttr: new FormControl(""),

        });
    }

    CreaFrmBonusCasa() {
        this.frmBonusCasa = new FormGroup({
            lav_id: new FormControl(0),
            lav_tipo: new FormControl(this.TipoIntervetoOfferta),
            lav_tipo_unita: new FormControl("", Validators.required),
            lav_tipo_bc: new FormControl("", Validators.required),
            lav_user: new FormControl(
                this.frmCliente.controls.cl_serramentista.value
            ),
            lav_cliente: new FormControl(null),
            lav_abitazione: new FormControl(0),
            lav_titolarita: new FormControl(0),
            lav_codice: new FormControl(""),
            lav_descrizione: new FormControl("", Validators.required),
            lav_tipologia_intervento: new FormControl("0"),
            lav_tipologia: new FormControl(""),
            lav_tipo_detrazione: new FormControl(
                "Bonus Casa",
                Validators.required
            ),
            lav_tipologia_agevolazione: new FormControl(
                "",
                Validators.required
            ),
            lav_zona_climatica: new FormControl("-"),
            lav_prodotto_installato: new FormControl("-"),
            lav_mq_installati: new FormControl(0),
            lav_mq_installati_co: new FormControl("0"),
            lav_transmittanza: new FormControl(0),
            lav_cancellato: new FormControl(0),
            lav_stato: new FormControl(1),
            lav_note: new FormControl(""),
            lav_asseverazione: new FormControl(""),
            lav_visto: new FormControl(""),
            lav_importo: new FormControl(0),
            lav_imp_asseverazione: new FormControl(0),
            lav_iva_offerta: new FormControl(""),
            lav_iva_prestazioni: new FormControl(""),
            lav_iva_attualizzazione: new FormControl(0),
            lav_pag_desc_1: new FormControl("SALDO", Validators.required),
            lav_pag_perc_1: new FormControl("100%", Validators.required),
            lav_pag_desc_2: new FormControl(""),
            lav_pag_perc_2: new FormControl(""),
            lav_pag_desc_3: new FormControl(""),
            lav_pag_perc_3: new FormControl(""),
            lav_consegna: new FormControl("90 giorni"),
            lav_cessionario: new FormControl(1),
            lav_vendita_infissi: new FormControl(0),
            lav_beni_significativi: new FormControl(0),
            lav_altri_beni: new FormControl(0),
            lav_posa_opera: new FormControl(0),
            lav_altre_opere: new FormControl(0),
            lav_prestazioni_prof: new FormControl(0),
            lav_chiusura: new FormControl("0"),
            lav_preventivo: new FormControl(""),
            lav_file_step2: new FormControl(""),
            lav_praticaenea: new FormControl(0),
            lav_costoenea: new FormControl(0),
            lav_finanz: new FormControl(0),
            lav_preventiviAttr: new FormControl(""),
        });
    }

    refreshUtenti(): void {
        this.utenteSrv.getUtenti("SE").subscribe((res) => {
            if (this.ruolo != "SE") {
                this.utenti = [...res.data];
            } else {
                this.utenti = [
                    ...res.data.filter(
                        (u) => u.id === Number(sessionStorage.getItem("id"))
                    ),
                ];
            }
        });
    }

    refreshClienti(): void {
        if (sessionStorage.getItem("ruolo") != "SE") {
            this.clientiSrv.getClienti().subscribe((res) => {
                this.clienti = [...res.data];
            });
        } else {
            this.clientiSrv
                .getClientiByUser(sessionStorage.getItem("id"))
                .subscribe((res) => {
                    this.clienti = [...res.data];
                });
        }
    }

    refresh(idCliente: string): void {
        this.clientiSrv.getCliente(idCliente).subscribe((res) => {
            this.cliente = { ...res };
            this.refreshCitta(
                { value: this.cliente.cl_regione_nascita },
                "cl_regione_nascita"
            );
            this.refreshCitta(
                { value: this.cliente.residenza.r_regione },
                "r_regione",
                "residenza"
            );
            this.cliente.cl_data_nascita = this.utcDatePipe.transform(
                this.cliente.cl_data_nascita,
                "yyyy-MM-dd",
                "dd/MM/yyyy"
            );
            this.cliente.documento.doc_emissione = this.utcDatePipe.transform(
                this.cliente.documento.doc_emissione,
                "yyyy-MM-dd",
                "dd/MM/yyyy"
            );
            this.cliente.documento.doc_scadenza = this.utcDatePipe.transform(
                this.cliente.documento.doc_scadenza,
                "yyyy-MM-dd",
                "dd/MM/yyyy"
            );
            this.frmCliente.patchValue(this.cliente);

            this.cl_regione_nascita.inputEL.nativeElement.value = this.cliente.cl_regione_nascita;
            this.r_regione.inputEL.nativeElement.value = this.cliente.residenza.r_regione;
            this.r_citta.inputEL.nativeElement.value = this.cliente.residenza.r_citta;

            const regione = this.cliente.cl_tipo === "giuridica" ? this.cliente.residenza.r_regione : this.cliente.cl_regione_nascita;

            if (regione != '') {
                this.nazioniSrv.getCittaByRegione(regione).subscribe((res) => {
                    this.citta = [...res.data];

                    if (this.cliente.cl_tipo === "fisica") {
                        this.cl_citta_nascita.inputEL.nativeElement.value = this.cliente.cl_citta_nascita;
                    }

                });
            }


            this.titoloScheda = `(${this.cliente.cl_cognome} ${this.cliente.cl_nome},  ${this.cliente.cl_cf})`;
            this.legaleVisible = this.cliente.cl_tipo === "giuridica";
            this.refreshAbitazioni(this.route.snapshot.paramMap.get("id"));

            this.OffeteApprovateCliente =
                this.cliente.cnt_offerte_approvate.cnt;
        });
    }

    refreshNazioni(): void {
        this.nazioniSrv
            .getNazioni()
            .subscribe((res) => (this.nazioni = [...res.data]));
    }

    refreshRegioni(): void {
        this.nazioniSrv
            .getRegioni()
            .subscribe((res) => (this.regioni = [...res.data]));
    }

    refreshCitta(ev, key: string, section: string = ""): void {
        const regione: string = ev.value;
        if (_.isEmpty(regione)) {
            return;
        }
        this.nazioniSrv.getCittaByRegione(regione).subscribe((res) => {
            this.citta = [...res.data];
        });
        if (!_.isEmpty(section)) {
            (this.frmCliente.controls[section] as FormGroup).controls[
                key
            ].setValue(regione);
            this.r_regione.inputEL.nativeElement.value = regione;
        } else {
            this.frmCliente.controls[key].setValue(regione);
            this.cl_regione_nascita.inputEL.nativeElement.value = regione;
        }
    }

    async refreshAbitazioni(id: string): Promise<void> {
        await new Promise((resolve) =>
            this.abitazioneSrv.getAbitazioni(id).subscribe((res) => {
                this.abitazioni = [...res.data];
                resolve("");
            })
        );
    }

    refreshInterventiCliente(idCliente: string): void {
        this.InterventiSrv.getInterventiCliente(idCliente).subscribe((res) => {
            this.lavori = [...res.data];
        });
    }

    refreshTitolariImmobili(): void {
        this.listTypeSrv.getTitolaritaImmobile().subscribe((res) => {
            this.titImmobile = [...res.data].filter((item) => item.ti_id != 6 && item.ti_id != 18);
        });
    }

    refreshTipologieAgevolazioni(): void {
        this.listTypeSrv.getTipoAgevolazione().subscribe((res) => {
            this.tipAgevolazioni = [...res.data];
        });
    }

    refreshTipoloInterventi(): void {
        this.listTypeSrv.getTipoInterventi().subscribe((res) => {
            const userInterventi = sessionStorage.getItem("interventi");
            if (userInterventi === "0") {
                this.tipoIntervento = [
                    ...res.data.filter((f) => f.tin_id == 1),
                ];
            } else if (userInterventi === "2") {
                this.tipoIntervento = [...res.data.filter((f) => f.tin_id > 1)];
            } else {
                this.tipoIntervento = [
                    ...res.data.filter((f) => f.tin_codice == "SI" || f.tin_codice =="SSCO" ),
                ];
            }
            this.tipoInterventoBC = [
                ...res.data.filter((f) => f.tin_codice == "BC"),
            ];
        });
    }

    refreshCategoriaCAtastale(): void {
        this.listTypeSrv.getCatastale().subscribe((res) => {
            this.categoriaCatastale = [...res.data];
        });
    }

    refreshZoneClimatiche(): void {
        this.listTypeSrv.getZonaClimatica().subscribe((res) => {
            this.zonelimatiche = [...res.data];
            this.filtroZonelimatiche = [...res.data];
        });
    }

    refreshProdottiInstallati(): void {
        this.listTypeSrv.getProdottiInstallati().subscribe((res) => {
            this.serramentoTipoProdotto = [...res.data];
            this.filtroTipoProdotto = [...res.data];
        });
    }

    submit(): void {
        const data = { ...this.frmCliente.value };
        data.cl_check_cf = false;
        data.cl_cognome = data.cl_cognome.toUpperCase();
        data.cl_nome = data.cl_nome.toUpperCase();

        if (typeof data.cl_regione_nascita === "undefined") {
            data.cl_regione_nascita = "";
        }

        if (typeof data.cl_provincia_nascita === "undefined") {
            data.cl_provincia_nascita = "";
        }

        if (typeof data.residenza.r_regione === "undefined") {
            data.residenza.r_regione = "";
        }

        if (typeof data.residenza.r_provincia === "undefined") {
            data.residenza.r_provincia = "";
        }

        this.convertiDateSubmit(data);

        if (data.cl_tipo == "fisica") {
            if (_.isEmpty(data.documento.doc_emissione)) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, Valorizzare la Data di Emissione Documento",
                });
                return;
            }
        }
        if (data.cl_tipo == "fisica") {
            if (_.isEmpty(data.documento.doc_scadenza)) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, Valorizzare la Data di Scadenza Documento",
                });
                return;
            }
        }
        //Controllo data Emissione Documento con data Scadenza
        if (data.cl_tipo == "fisica") {
            if (data.documento.doc_emissione >= data.documento.doc_scadenza) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, Data Emissione Documento Maggiore della Scadenza",
                });
                return;
            }
        }
        //Controllo Lunghezza CF Cliente persona Fisica
        if (data.cl_tipo == "fisica") {
            if (!_.isEmpty(data.cl_cf)) {
                if (String(data.cl_cf).length != 16) {
                    this.msgService.add({
                        key: "tst",
                        severity: "error",
                        summary: `ERRORE`,
                        detail: "Attenzione, Codice Fiscale NON valido",
                    });
                    return;
                }
            }
        }
        //Controllo assenza data di nascita
        if (data.cl_tipo == "fisica") {
            if (_.isEmpty(data.cl_data_nascita)) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, Valorizzare la Data di Nascita",
                });
                return;
            }
        }
        if (data.cl_tipo == "giuridica") {
            if (!_.isEmpty(data.cl_cf)) {
                //Controllo Lunghezza CF Cliente persona Giuridica
                if (
                    String(data.cl_cf).length != 16 &&
                    String(data.cl_cf).length != 11
                ) {
                    this.msgService.add({
                        key: "tst",
                        severity: "error",
                        summary: `ERRORE`,
                        detail: "Attenzione, Codice Fiscale CLIENTE NON valido",
                    });
                    return;
                }
            }
            //Controllo Lunghezza CF Legale persona Giuridica
            if (!_.isEmpty(data.legale.rl_cf)) {
                if (String(data.legale.rl_cf).length != 16) {
                    this.msgService.add({
                        key: "tst",
                        severity: "error",
                        summary: `ERRORE`,
                        detail: "Attenzione, Codice Fiscale LEGALE NON valido",
                    });
                    return;
                }
            }
        }
        //Controllo Lunghezza Partita Iva  persona Giuridica
        if (data.cl_tipo == "giuridica") {
            if (!_.isEmpty(data.legale.cl_piva)) {
                if (String(data.legale.cl_piva).length != 11) {
                    this.msgService.add({
                        key: "tst",
                        severity: "error",
                        summary: `ERRORE`,
                        detail: "Attenzione, Partita Iva NON valida",
                    });
                    return;
                }
            }
        }

        //Controllo CF e PI entrambi vuoti
        if (_.isEmpty(data.cl_cf) && _.isEmpty(data.cl_piva)) {
            this.msgService.add({
                key: "tst",
                severity: "error",
                summary: `ERRORE`,
                detail: "Attenzione, Errore nei campi Codice Fiscale/Partita Iva",
            });
            return;
        }

        //Controllo CF OCCORRENZE CON ALTRI GIA' SALVATI
        if (this.id == "0") {
            let trovaAnagrafica: { cl_cognome: string; cl_nome: string }[] = [];
            this.clienti.find((x) => {
                if (x.cl_cf == data.cl_cf) {
                    trovaAnagrafica.push({
                        cl_cognome: x.cl_cognome,
                        cl_nome: x.cl_nome,
                    });
                }
            });
            if (trovaAnagrafica.length > 0) {
                let messageStr: string =
                    "Attenzione sono state rilevate più coccorrenze con lo stesso Codice Fiscale:";
                trovaAnagrafica.forEach((x) => {
                    messageStr =
                        messageStr + "<br> " + x.cl_cognome + " " + x.cl_nome;
                });
                this.confirmService.confirm({
                    header: `CODICE FISCALE GIA' PRESENTE, NON E' POSSIBILE SALVARE I DATI`,
                    message: messageStr,
                    icon: "pi  pi-question-circle",
                    acceptVisible: false,
                    rejectLabel: "Annulla",
                    accept: () => {
                        if (data.cl_ha_piva == "SI") {
                            if (_.isEmpty(data.cl_piva)) {
                                this.msgService.add({
                                    key: "tst",
                                    severity: "error",
                                    summary: `Attenzione`,
                                    detail: "Partita Iva NON PRESENTE!",
                                });
                            } else {
                                this.SalvaAnagrafica(data);
                            }
                        } else {
                            data.cl_ha_piva == "";
                            this.SalvaAnagrafica(data);
                        }
                    },
                    reject: () => {
                        return true;
                    },
                });
            } else {
                this.SalvaAnagrafica(data);
            }
        } else {
            if (data.cl_ha_piva == "SI") {
                if (_.isEmpty(data.cl_piva)) {
                    this.msgService.add({
                        key: "tst",
                        severity: "error",
                        summary: `Attenzione`,
                        detail: "Partita Iva NON PRESENTE!",
                    });
                } else {
                    this.SalvaAnagrafica(data);
                }
            } else {
                data.cl_ha_piva == "";
                this.SalvaAnagrafica(data);
            }
        }
    }

    SalvaAnagrafica(data) {
        this.clientiSrv.addCliente(data).subscribe((res) => {
            this.msgService.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Cliente`,
                detail: "Cliente Salvato!",
            });
            this.router.navigate([`SchedeIntervento/${res.message}`]);
            this.clientiSrv.editUser(false);
        });
    }

    submitIntervento(): void {
        if (this.frmLavoro.controls.lav_tipologia_intervento.value == 1) {
            this.frmLavoro.controls.lav_tipologia.setValue("SI");
        } else {
            this.frmLavoro.controls.lav_tipologia.setValue("SSCO");
        }

        this.buttonDisabled = true;
        this.InterventiSrv.addIntervento(this.frmLavoro.value).subscribe(
            (res) => {
                this.msgService.add({
                    key: "tst",
                    severity: res.res === "ok" ? "success" : "error",
                    summary: `Scheda Intervento`,
                    detail: res.message,
                });
                this.buttonDisabled = false;
                this.abitazioneScelta = null;
                this.closeIntervento();
                this.refreshInterventiCliente(this.id);
                this.CreaFrmLavoro();
                this.tabChange = 2;
            }
        );
    }

    submitInterventoBC(): void {
        let controlloDrop: string =
            this.frmBonusCasa.controls.lav_tipo_bc.value;
        if (
            controlloDrop.includes("Manutenzione ordinaria") &&
            this.frmBonusCasa.controls.lav_tipo_unita.value ==
            "Singola unità abitativa"
        ) {
            this.msgService.add({
                key: "tst",
                severity: "error",
                summary: `Scheda Intervento`,
                detail: "Errore! 'Manutenzione ordinaria' selezionabile solo in caso di 'Parti comuni condominiali'",
            });
            return;
        }
        this.buttonDisabled = true;
        this.frmBonusCasa.controls.lav_tipologia.setValue("BC");
        this.InterventiSrv.addIntervento(this.frmBonusCasa.value).subscribe(
            (res) => {
                this.msgService.add({
                    key: "tst",
                    severity: res.res === "ok" ? "success" : "error",
                    summary: `Scheda Intervento`,
                    detail: res.message,
                });
                this.buttonDisabled = false;
                this.abitazioneScelta = null;
                this.closeIntervento();
                this.refreshInterventiCliente(this.id);
                this.CreaFrmBonusCasa();
                this.tabChange = 2;
            }
        );
    }

    submitAbitazione(): void {
        this.abitazioneSrv
            .addAbitazioni(this.frmAbitazione.value)
            .subscribe((res) => {
                this.msgService.add({
                    key: "tst",
                    severity: res.res === "ok" ? "success" : "danger",
                    summary: `Abitazione`,
                    detail: res.message,
                });
                this.refreshAbitazioni(this.id);
                this.creaFormAbitazione();
                this.modalAbitazione = false;
            });
    }

    delAbitazione(idAbitazione: number): void {
        this.confirmService.confirm({
            header: `Elimina Abitazione`,
            message: "Sicuro di Eseguire l'operazione di Cancellazione?",
            icon: "pi  pi-question-circle",
            accept: () => {
                this.abitazioneSrv
                    .delAbitazioni(idAbitazione, true)
                    .subscribe((res) => {
                        this.msgService.add({
                            key: "tst",
                            severity: res.res === "ok" ? "success" : "danger",
                            summary: `Elimina Abitazione`,
                            detail: res.message,
                        });
                        this.refreshAbitazioni(this.id);
                    });
            },
        });
    }

    delIntervento(idIntervento: number, stato: number): void {
        this.confirmService.confirm({
            header: `Elimina Intervento`,
            message: "Sicuro di Eseguire l'operazione di Cancellazione?",
            icon: "pi  pi-question-circle",
            accept: () => {
                this.InterventiSrv.delIntervento(
                    idIntervento,
                    true,
                    stato
                ).subscribe((res) => {
                    this.msgService.add({
                        key: "tst",
                        severity: res.res === "ok" ? "success" : "danger",
                        summary: `Elimina Intervento`,
                        detail: res.message,
                    });
                    this.abitazioneScelta = null;
                    this.refreshInterventiCliente(this.id);
                    this.CreaFrmLavoro();
                });
            },
        });
    }

    tipologiaChange(ev): void {
        this.legaleVisible = ev.value === "giuridica";
    }

    HaPartitaChange(ev): void {
        if (ev.value == "NO") {
            this.frmCliente.controls.cl_piva.setValue("");
        }
    }

    convertiDateSubmit = (data: Cliente) => {
        data.cl_data_nascita = this.utcDatePipe.transform(
            data.cl_data_nascita,
            "dd/MM/yyyy",
            "yyyy-MM-dd"
        );
        data.documento.doc_emissione = this.utcDatePipe.transform(
            data.documento.doc_emissione,
            "dd/MM/yyyy",
            "yyyy-MM-dd"
        );
        data.documento.doc_scadenza = this.utcDatePipe.transform(
            data.documento.doc_scadenza,
            "dd/MM/yyyy",
            "yyyy-MM-dd"
        );
    };

    checkError() { }

    EditAbitazione(abitazione: Abitazioni): void {
        this.OffeteApprovateAbitazione = abitazione.cnt_offerte_approvate.cnt;
        this.frmAbitazione.patchValue(abitazione);
        this.modalAbitazione = true;
        if (this.ruolo === "SE") {
            this.enabledSerr = false;
        } else {
            this.enabledSerr = true;
        }
    }

    EditIntervento(intervento: LavoriTutti, detrazione: string): void {
        this.abitazioneScelta = intervento;
        this.abitazioneScelta.ab_cap = intervento.ab_cap;
        this.TipoIntervetoOfferta = "intervento";
        this.TipoIntervetoOfferta = "intervento";
        if (detrazione == "ECO Bonus") {
            this.CreaFrmLavoro();
            this.frmLavoro.patchValue(intervento);
            if (this.frmLavoro.controls.lav_tipologia_agevolazione.value == 1) {
                this.frmLavoro.controls.lav_tipologia_agevolazione.setValue(50);
            }
            let ricordaTrasmittanza =
                this.frmLavoro.controls.lav_transmittanza.value;
            this.FiltraTipologiaIntervento(
                this.frmLavoro.controls.lav_tipologia_intervento.value
            );
            this.frmLavoro.controls.lav_tipo.setValue(
                this.TipoIntervetoOfferta
            );
            this.frmLavoro.controls.lav_transmittanza.setValue(
                ricordaTrasmittanza
            );
            this.modalIntervento = true;
        } else {
            this.CreaFrmBonusCasa();
            this.frmBonusCasa.controls.lav_tipologia.setValue("BC");
            this.frmBonusCasa.patchValue(intervento);
            this.frmBonusCasa.controls.lav_tipo.setValue(
                this.TipoIntervetoOfferta
            );
            this.modalInterventoBonusCasa = true;
        }
    }

    newIntervento(abitazione: Abitazioni, tipodetrazione: string): void {
        this.bloccaTipoUnita = false;
        this.CreaFrmLavoro();
        this.CreaFrmBonusCasa();
        this.abitazioneScelta = abitazione;
        this.TipoIntervetoOfferta = "intervento";
        this.frmLavoro.controls.lav_tipo_detrazione.setValue(tipodetrazione);
        if (tipodetrazione === "ECO Bonus") {
            this.frmLavoro.controls.lav_tipo_unita.setValue(
                "Singola unità abitativa"
            );
            this.bloccaTipoUnita = true;
        }
        this.frmLavoro.controls.lav_tipo.setValue(this.TipoIntervetoOfferta);
        this.frmLavoro.controls.lav_cliente.setValue(this.frmCliente.controls.cl_id.value);
        this.frmLavoro.controls.lav_abitazione.setValue(this.abitazioneScelta.ab_id);
        this.modalIntervento = true;
        Utility.updateFormValidity(this.frmLavoro);
    }



    showDialog(): void {
        this.OffeteApprovateAbitazione = 0;
        this.modalAbitazione = true;
        Utility.updateFormValidity(this.frmAbitazione);
    }


    newInterventoBonusCasa(abitazione: Abitazioni, tipodetrazione: string): void {
        this.CreaFrmLavoro();
        this.CreaFrmBonusCasa();
        this.abitazioneScelta = abitazione;
        this.changeFrom_TipologiaIntervento(0);
        this.TipoIntervetoOfferta = "intervento";
        this.utenteAttrsSrv
            .getUserAttrs(this.frmBonusCasa.controls.lav_user.value, "bc")
            .subscribe((res) => {
                let agevolazione: string = [...res.data].find((x) => x.ua_chiave == `bc_detrazione`).ua_valore;
                let cessionario: string = [...res.data].find((x) => x.ua_chiave == `bc_cessionario`).ua_valore;

                this.frmBonusCasa.controls.lav_tipologia_agevolazione.setValue(agevolazione);
                this.frmBonusCasa.controls.lav_tipologia.setValue("BC");
                this.frmBonusCasa.controls.lav_cessionario.setValue(cessionario);
                this.frmBonusCasa.controls.lav_tipo_detrazione.setValue(tipodetrazione);
                this.frmBonusCasa.controls.lav_tipo.setValue(this.TipoIntervetoOfferta);
                this.frmBonusCasa.controls.lav_cliente.setValue(this.frmCliente.controls.cl_id.value);
                this.frmBonusCasa.controls.lav_abitazione.setValue(this.abitazioneScelta.ab_id);
                this.modalInterventoBonusCasa = true;
                Utility.updateFormValidity(this.frmBonusCasa);
            });
    }

    cancelModale(): void {
        this.modalAbitazione = false;
        this.creaFormAbitazione();
    }

    closeIntervento(): void {
        this.CreaFrmLavoro();
        this.CreaFrmBonusCasa();
        this.abitazioneScelta = null;
        this.modalIntervento = false;
        this.modalInterventoBonusCasa = false;
    }

    ConcideResidenza(): void {
        this.frmAbitazione.controls.ab_indirizzo.setValue(
            this.frmCliente.controls["residenza"].value.r_indirizzo
        );
        this.frmAbitazione.controls.ab_civico.setValue(
            this.frmCliente.controls["residenza"].value.r_civico
        );
        this.frmAbitazione.controls.ab_regione.setValue(
            this.frmCliente.controls["residenza"].value.r_regione
        );
        this.frmAbitazione.controls.ab_citta.setValue(
            this.frmCliente.controls["residenza"].value.r_citta
        );
        this.frmAbitazione.controls.ab_provincia.setValue(
            this.frmCliente.controls["residenza"].value.r_provincia
        );
        this.frmAbitazione.controls.ab_cap.setValue(
            this.frmCliente.controls["residenza"].value.r_cap
        );
    }

    Trasmittanza(ev): void {
        if (ev.value === "A" || ev.value === "B") {
            this.frmLavoro.controls.lav_transmittanza.setValue(
                "<= a 2,60 W/m2k"
            );
        } else if (ev.value === "C") {
            this.frmLavoro.controls.lav_transmittanza.setValue(
                "<= a 1,75 W/m2k"
            );
        } else if (ev.value === "D") {
            this.frmLavoro.controls.lav_transmittanza.setValue(
                "<= a 1,67 W/m2k"
            );
        } else if (ev.value === "E") {
            this.frmLavoro.controls.lav_transmittanza.setValue(
                "<= a 1,30 W/m2k"
            );
        } else if (ev.value === "F") {
            this.frmLavoro.controls.lav_transmittanza.setValue(
                "<= a 1,00 W/m2k"
            );
        }
    }

    search(ev, type): void {
        const query: string = ev.query;
        switch (true) {
            case type === "regioni":
                this.regioniSuggestion = [
                    ...this.regioni.filter(
                        (r: Regioni) =>
                            r.value
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) !== -1
                    ),
                ];
                break;
            case type === "citta":
                this.cittaSuggestion = [
                    ...this.citta.filter(
                        (c: Citta) =>
                            c.value
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) !== -1
                    ),
                ];
                break;
        }
    }

    selectProvincia(ev: Citta, key: string, section: string = ""): void {
        if (!_.isEmpty(section)) {
            (this.frmCliente.controls[section] as FormGroup).controls[
                key
            ].setValue(ev.value);
            (this.frmCliente.controls[section] as FormGroup).controls[
                "r_provincia"
            ].setValue(ev.provincia);
            this.r_citta.inputEL.nativeElement.value = ev.value;
            return;
        }
        this.frmCliente.controls[key].setValue(ev.value);
        this.cl_citta_nascita.inputEL.nativeElement.value = ev.value;
        this.frmCliente.controls["cl_provincia_nascita"].setValue(ev.provincia);
    }

    CambioTipologiaIntervento(ev): void {
        this.changeFrom_TipologiaIntervento(ev.value);
    }

    changeFrom_TipologiaIntervento(idTipInt: number): void {
        let tipologia: string;
        if (idTipInt != 0) {
            if (
                this.frmLavoro.controls.lav_tipo_detrazione.value !=
                "Bonus Casa"
            ) {
                if (idTipInt === 1) {
                    tipologia = "si";
                } else {
                    tipologia = "ssco";
                }
            }
        } else {
            tipologia = "bc";
        }

        this.utenteAttrsSrv
            .getUserAttrs(this.frmLavoro.controls.lav_user.value, tipologia)
            .subscribe((res) => {
                let agevolazione: string = [...res.data].find(
                    (x) => x.ua_chiave == `${tipologia}_detrazione`
                ).ua_valore;
                let cessionario: string = [...res.data].find(
                    (x) => x.ua_chiave == `${tipologia}_cessionario`
                ).ua_valore;
                this.frmLavoro.controls.lav_tipologia_agevolazione.setValue(
                    agevolazione
                );
                this.frmLavoro.controls.lav_chiusura.setValue("");
                this.frmLavoro.controls.lav_zona_climatica.setValue("");
                this.frmLavoro.controls.lav_prodotto_installato.setValue("");
                this.frmLavoro.controls.lav_cessionario.setValue(cessionario);

                if (idTipInt === 1) {
                    this.frmLavoro.controls.lav_transmittanza.setValue("0");
                    if (new Date() >= new Date("2022-05-05")) {
                        this.filtroChiusuraOscurante =
                            this.chiusuraOscurante.filter(
                                (f) => f.value === 99
                            );
                        this.frmLavoro.controls.lav_chiusura.setValue(99);
                        this.frmLavoro.controls.lav_prodotto_installato.setValue(
                            "Serramento o Serramento + chiusura oscurante"
                        );
                    } else {
                        this.filtroChiusuraOscurante =
                            this.chiusuraOscurante.filter((f) => f.value < 2);
                        this.frmLavoro.controls.lav_chiusura.setValue(0);
                        this.frmLavoro.controls.lav_prodotto_installato.setValue(
                            "Serramento"
                        );
                    }

                    this.filtroZonelimatiche = this.zonelimatiche.filter(
                        (f) =>
                            f.zc_codice === "A" ||
                            f.zc_codice === "B" ||
                            f.zc_codice === "C" ||
                            f.zc_codice === "D" ||
                            f.zc_codice === "E" ||
                            f.zc_codice === "F"
                    );
                    this.filtroTipoProdotto =
                        this.serramentoTipoProdotto.filter((f) => f.pi_id <= 2);
                } else {
                    this.frmLavoro.controls.lav_transmittanza.setValue(
                        "0,08 Km2/w <= Rsup <= 0,455 Km2/w"
                    );
                    this.filtroChiusuraOscurante =
                        this.chiusuraOscurante.filter((f) => f.value === 2);
                    this.frmLavoro.controls.lav_chiusura.setValue(2);
                    this.filtroZonelimatiche = this.zonelimatiche.filter(
                        (f) =>
                            f.zc_codice != "A" &&
                            f.zc_codice != "B" &&
                            f.zc_codice != "C" &&
                            f.zc_codice != "D" &&
                            f.zc_codice != "E" &&
                            f.zc_codice != "F"
                    );
                    this.filtroTipoProdotto =
                        this.serramentoTipoProdotto.filter((f) => f.pi_id > 2);

                    if (idTipInt === 2) {
                        this.frmLavoro.controls.lav_prodotto_installato.setValue(
                            "-"
                        );
                    }
                    if (idTipInt === 5) {
                        this.frmLavoro.controls.lav_zona_climatica.setValue(
                            "-"
                        );
                    }
                }
            });
    }

    FiltraTipologiaIntervento(idTipInt: number): void {
        if (idTipInt === 1) {
            this.frmLavoro.controls.lav_transmittanza.setValue("0");
            if (
                new Date(this.frmLavoro.controls.lav_data_aggiunta.value) >=
                new Date("2022-05-05")
            ) {
                this.filtroChiusuraOscurante = this.chiusuraOscurante.filter(
                    (f) => f.value === 99
                );
                this.frmLavoro.controls.lav_prodotto_installato.setValue(
                    "Serramento o Serramento + chiusura oscurante"
                );
            } else {
                this.filtroChiusuraOscurante = this.chiusuraOscurante.filter(
                    (f) => f.value < 2
                );
                // this.frmLavoro.controls.lav_prodotto_installato.setValue("Serramento")
            }
            this.filtroZonelimatiche = this.zonelimatiche.filter(
                (f) =>
                    f.zc_codice === "A" ||
                    f.zc_codice === "B" ||
                    f.zc_codice === "C" ||
                    f.zc_codice === "D" ||
                    f.zc_codice === "E" ||
                    f.zc_codice === "F"
            );
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter(
                (f) => f.pi_id <= 2
            );
        } else {
            this.frmLavoro.controls.lav_transmittanza.setValue(
                " 0,08 Km2/w <= Rsup <= 0,455 Km2/w"
            );
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter(
                (f) => f.value === 2
            );
            this.filtroZonelimatiche = this.zonelimatiche.filter(
                (f) =>
                    f.zc_codice != "A" &&
                    f.zc_codice != "B" &&
                    f.zc_codice != "C" &&
                    f.zc_codice != "D" &&
                    f.zc_codice != "E" &&
                    f.zc_codice != "F"
            );
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter(
                (f) => f.pi_id != 1
            );
        }
    }

    cambiaTipologiaProdotto(ev): void {
        if (ev.value === 1) {
            this.frmLavoro.controls.lav_prodotto_installato.setValue(
                "Serramento + chiusura oscurante"
            );
        } else if (ev.value === 0) {
            this.frmLavoro.controls.lav_prodotto_installato.setValue(
                "Serramento"
            );
        } else if (ev.value === 99) {
            this.frmLavoro.controls.lav_prodotto_installato.setValue(
                "Serramento o Serramento + chiusura oscurante"
            );
        }
    }

    resetDatiNascita(): void {
        this.frmCliente.controls.cl_regione_nascita.setValue("");
        this.frmCliente.controls.cl_citta_nascita.setValue("");
        this.frmCliente.controls.cl_provincia_nascita.setValue("");
    }

    resetDatiResidenza(): void {
        (
            this.frmCliente.controls["residenza"] as FormGroup
        ).controls.r_regione.setValue("");
        (
            this.frmCliente.controls["residenza"] as FormGroup
        ).controls.r_citta.setValue("");
        (
            this.frmCliente.controls["residenza"] as FormGroup
        ).controls.r_provincia.setValue("");
    }

    BadgeColor(stato: number): string {
        if (stato === 1) {
            return "info";
        } else if (stato === 2) {
            return "warning";
        } else if (stato === 3) {
            return "success";
        } else {
            return "danger";
        }
    }

    OpenZonaClimatica_ChiusiraOscurate(eve): void {
        if (this.frmLavoro.controls.lav_tipologia_intervento.value === 1) {
            this.selZonaClimatica = "";
            this.opZC.show(eve);
        } else {
            this.ArrEsposizioneSchermatureSolari = [];
            this.opESS.show(eve);
        }
    }

    OpenEspChiusureOsc(eve): void {
        this.ArrEsposizioneChiusureOscuranti = [];
        this.opECO.show(eve);
    }

    Open_BC(eve): void {
        this.ArrTipologiaBC = [];
        this.opBC.show(eve);
    }

    OpenGTOT(eve): void {
        this.selGTOT = "";
        this.opGTOT.show(eve);
    }

    Sel_ZC_ESS(tipoScelto: string): void {
        if (tipoScelto === "ESS") {
            this.frmLavoro.controls.lav_zona_climatica.setValue(
                this.ArrEsposizioneSchermatureSolari.join(",")
            );
            this.opESS.hide();
        } else if (tipoScelto === "ZC") {
            this.frmLavoro.controls.lav_zona_climatica.setValue(
                this.selZonaClimatica
            );
            this.opZC.hide();
        }
    }

    Sel_EsposizioneChiusureOscuranti(): void {
        this.frmLavoro.controls.lav_prodotto_installato.setValue(
            this.ArrEsposizioneChiusureOscuranti.join(",")
        );
        this.opECO.hide();
    }

    Sel_BC(): void {
        this.frmBonusCasa.controls.lav_tipo_bc.setValue(
            this.ArrTipologiaBC.join(",")
        );
        this.opBC.hide();
    }

    Sel_GTOT(): void {
        this.frmLavoro.controls.lav_chiusura.setValue(this.selGTOT);
        this.opGTOT.hide();
    }

    calcolaCf() { }

    controlloAbitazione(catasto: number) {
        if (catasto == 10 || catasto >= 12) {
            return false;
        }
        return true;
    }
}
